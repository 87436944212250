var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[(!_vm.isBlocked)?_c('v-card',[_c('v-data-iterator',{attrs:{"items":_vm.portales,"items-per-page":6,"search":_vm.search},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c('v-container',{staticClass:"pa-2",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"auto","md":"4"}},[_c('v-card',{staticClass:"pb-1 elevation-1",staticStyle:{"min-height":"300px"},attrs:{"border":true,"flat":"","to":item.linkto}},[_c('v-img',{attrs:{"src":item.archivo_adjunto
                        ? item.archivo_adjunto
                        : 'https://www.acara.org.ar/img/img_acara_news.jpg',"height":"150"}}),_c('p',{staticClass:"text-h3 mb-2 pl-2 pr-2 pt-4 text-center",staticStyle:{"height":"50px"}},[_c('v-icon',{domProps:{"textContent":_vm._s(item.icono)}})],1),_c('p',{staticClass:"text-h3 mb-2 pl-2 pr-2 text-center"},[_vm._v(" "+_vm._s(item.titulo)+" ")])],1)],1)}),1)],1)]}}],null,false,2013607153)})],1):_c('v-card',[_c('v-card',{staticClass:"ml-0 mr-0 pr-6",style:({
          borderRadius: '10px',
          margin: '4px',
          backgroundColor: _vm.$cv('secondary') + '20',
        }),attrs:{"border":""}},[_c('p',{staticClass:"text-h4 pt-4 text-center",staticStyle:{"line-height":"1.5"}},[_c('b',{},[_vm._v("ATENCIÓN")])]),_c('hr',{staticClass:"ml-5"}),_c('div',{staticClass:"carritoMsg__div"},[_c('p',{staticClass:"text-h4 pt-4 pb-4 text-center",staticStyle:{"line-height":"1.5"}},[_c('b',{},[_vm._v(_vm._s(_vm.messageBlocked))])])])])],1),(_vm.notifications.length > 0)?_c('div',_vm._l((_vm.notifications),function(n,i){return _c('v-alert',{key:`item-${i}`,attrs:{"type":n.type,"prominent":"","variant":"outlined","border":"left","text":""},domProps:{"innerHTML":_vm._s(n.text)},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}})}),1):_vm._e(),_c('v-snackbar',{attrs:{"bottom":true,"color":_vm.color,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }