<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card v-if="!isBlocked">
      <v-data-iterator :items="portales" :items-per-page="6" :search="search">
        <template v-slot:default="{ items }">
          <v-container class="pa-2" fluid>
            <v-row dense>
              <v-col v-for="item in items" :key="item.id" cols="auto" md="4">
                <v-card
                  class="pb-1 elevation-1"
                  :border="true"
                  flat
                  style="min-height: 300px"
                  :to="item.linkto"
                >
                  <v-img
                    :src="
                      item.archivo_adjunto
                        ? item.archivo_adjunto
                        : 'https://www.acara.org.ar/img/img_acara_news.jpg'
                    "
                    height="150"
                  ></v-img>
                  <p
                    class="text-h3 mb-2 pl-2 pr-2 pt-4 text-center"
                    style="height: 50px"
                  >
                    <v-icon v-text="item.icono" />
                  </p>
                  <p class="text-h3 mb-2 pl-2 pr-2 text-center">
                    {{ item.titulo }}
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-iterator>
    </v-card>
    <v-card v-else>
      <v-card
        border
        class="ml-0 mr-0 pr-6"
        :style="{
          borderRadius: '10px',
          margin: '4px',
          backgroundColor: $cv('secondary') + '20',
        }"
        ><p class="text-h4 pt-4 text-center" style="line-height: 1.5">
          <b class="">ATENCIÓN</b>
        </p>
        <hr class="ml-5" />
        <div class="carritoMsg__div">
          <p class="text-h4 pt-4 pb-4 text-center" style="line-height: 1.5">
            <b class="">{{ messageBlocked }}</b>
          </p>
        </div>
      </v-card>
    </v-card>
    <div v-if="notifications.length > 0">
      <v-alert
        v-for="(n, i) in notifications"
        :key="`item-${i}`"
        v-model="alert"
        :type="n.type"
        prominent
        variant="outlined"
        border="left"
        v-html="n.text"
        text
      >
      </v-alert>
    </div>
    <!--<br />
		<br />
		<br />
		<br />
		<br />
		<br />
		<div class="d-flex flex-column justify-space-between align-center">
			<v-img src="/img/acara_logo_2.png" cover></v-img>
		</div>
		<br />
		<br />
		<br />
		<br />
		<br />
		<br />
    -->

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  name: "DashboardDashboard",

  data() {
    return {
      width: 300,
      aspectRatio: 16 / 9,
      snackbar: false,
      snackbarNotif: false,
      visible: true,
      text: "Registro Insertado",
      color: "success",
      timeout: 4000,
      alert: true,
      notifications: [
        /*{ text: "hola <b>Usuario</b>", type: "info" }*/
      ],
      componentKey: 0,
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false,
      },
      search: "",
      portales: [],
      isBlocked: false,
      messageBlocked: "",
    };
  },

  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },

    setItemsIndex() {
      //evaluo los accesos en base a los permisos
      //if (this.$can(["comprar_elementos_registrales"]) === true) {
      if (this.$isEnabledProperty(this.$COMPRAR_ELEMENTOS_REGISTRALES)) {
        console.log("puede comprar elementos registrales");
        this.portales.push({
          foto: "",
          icono: "mdi-cart",
          titulo: "Elementos Registrales",
          linkto: "elementos_registrales",
        });
      }

      if (this.$can(["vender_estampillas_anmac"]) === true) {
        this.portales.push({
          foto: "",
          icono: "mdi-cart",
          titulo: "Venta Estampillas Anmac",
          linkto: "estampillas_ANMAC_venta",
        });
      }

      //if (this.$can(["comprar_elementos_aduana"]) === true) {
      if (this.$isEnabledProperty(this.$COMPRAR_ELEMENTOS_ADUANA)) {
        console.log("puede comprar elementos aduana");
        this.portales.push({
          foto: "",
          icono: "mdi-cart",
          titulo: "Comprar Elementos Aduana",
          linkto: "elementos_aduana_compra",
        });
      }

      if (this.$can(["vender_elementos_aduana"]) === true) {
        this.portales.push({
          foto: "",
          icono: "mdi-cart",
          titulo: "Vender Elementos Aduana",
          linkto: "elementos_aduana_venta",
        });
      }

      if (this.$can(["elementos_aduana_list"]) === true) {
        this.portales.push({
          foto: "",
          icono: "mdi-cart",
          titulo: "Elementos Aduana",
          linkto: "elementos_aduana",
        });
      }

      //if (this.$can(["listado_transacciones"]) === true) {
      this.portales.push({
        foto: "",
        icono: "mdi-format-list-bulleted-square",
        titulo: "Listado de Transacciones",
        linkto: "portal/transacciones",
      });
      //}

      if (this.$can(["informe_stock_estampillas"]) === true) {
        this.portales.push({
          foto: "",
          icono: "mdi-format-list-bulleted-square",
          titulo: "Gestión de Stock de Estampillas",
          linkto: "stock-estampillas/gestion_stock_estampillas",
        });
      }

      //if (this.$can(["adquirir_saldo_cta_cte"]) === true) {
      if (this.$isEnabledProperty(this.$ADQUIRIR_SALDO_CTA_CTE)) {
        console.log("puede comprar adquirir saldo cta cte");
        this.portales.push({
          foto: "",
          icono: "mdi-bank",
          titulo: "Adquirir Saldo en Cuenta Corriente",
          linkto: "adquirir_saldo_cta_cte",
        });
      }

      //if (this.$can(["listado_cuenta_corriente"]) === true) {
      if (this.$isEnabledProperty(this.$LISTADO_CTA_CTE)) {
        console.log("puede listar cta cte");
        this.portales.push({
          foto: "",
          icono: "mdi-scale-balance",
          titulo: "Consultar Cuenta Corriente",
          linkto: "cuenta_corriente_consulta",
        });
      }
      if (this.$can(["cuenta_corriente_create"]) === true) {
        this.portales.push({
          foto: "",
          icono: "mdi-scale-balance",
          titulo: "Cuenta Corriente",
          linkto: "cuenta_corriente",
        });
      }
    },
  },
  created() {
    console.log("Dashboard creado");
    console.log(this.$store.state.user.sapProperties.length);
    if (typeof this.$store.state.user.sapProperties.length == "undefined") {
      setTimeout(() => {
        if (this.$store.state.user.sapProperties.length > 0) {
          if (this.$store.state.user.sapProperties.U_BloqueoWeb == "tNO") {
            this.setItemsIndex();
            Swal.close();
          } else {
            this.isBlocked = true;
            this.messageBlocked =
              this.$store.state.user.sapProperties.U_MensajeWeb;
            // Swal.close();

            //this.messageBlocked = "Su usuario se encuentra bloqueado.";
          }
        }
        //cuando todos los usuarios esten en sap, hay que comentar esta opcion
        else {
          this.setItemsIndex();
          // Swal.close();
        }
      }, 2000);
    } else {
      this.setItemsIndex();
    }
  },
  mounted: function () {
    if (this.notifications.length > 0) {
      this.snackbarNotif = true;
    }
  },
};
</script>
